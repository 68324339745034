import type { SearchQuery, SearchQueryParamater } from '~/types/search'

const allowableParamaters = new Set<SearchQueryParamater>(['nelat', 'nelng', 'swlat', 'swlng', 'searchaddress', 'page'])

const validator: Record<SearchQueryParamater, (value: any) => boolean> = {
  nelat: (value) => value == null || isLatitude(value),
  nelng: (value) => value == null || isLongitude(value),
  swlat: (value) => value == null || isLatitude(value),
  swlng: (value) => value == null || isLongitude(value),
  searchaddress: (value) => value == null || typeof value === 'string',
  page: (value) => value == null || value > 0
}
const transformer: Record<SearchQueryParamater, (value: any) => any> = {
  nelat: (value) => (value == null ? null : Number(value)),
  nelng: (value) => (value == null ? null : Number(value)),
  swlat: (value) => (value == null ? null : Number(value)),
  swlng: (value) => (value == null ? null : Number(value)),
  searchaddress: (value) => (value == null || (value as string).trim() === '' ? null : (value as string).trim()),
  page: (value) => (value == null || value < 2 ? null : Number(value))
}
const mapper: Record<SearchQueryParamater, keyof SearchQuery> = {
  nelat: 'NeLat',
  nelng: 'NeLng',
  swlat: 'SwLat',
  swlng: 'SwLng',
  searchaddress: 'SearchAddress',
  page: 'Page'
}

export default () => {
  const router = useRouter()

  const current = computed(() => {
    return Object.keys(router.currentRoute.value.query)
      .map((p) => p.toLowerCase() as SearchQueryParamater)
      .filter((p) => allowableParamaters.has(p) && validator[p](router.currentRoute.value.query[p]))
      .reduce((m, c) => {
        const tc = mapper[c]
        m[tc] = transformer[c](router.currentRoute.value.query[tc])
        return m
      }, {} as SearchQuery)
  })

  const currentHasMapBounds = computed(
    () =>
      current.value.NeLat != null &&
      current.value.NeLng != null &&
      current.value.SwLat != null &&
      current.value.SwLng != null
  )

  const update = async (key: SearchQueryParamater, value: any) => {
    const query = { ...router.currentRoute.value.query }

    if (!allowableParamaters.has(key))
      throw new Error(
        `'${key}' is not an allowable query paramater. Allowable values are: ${[...allowableParamaters.keys()].join(', ')}`
      )

    if (!validator[key](value)) return false

    const mappedKey = mapper[key]
    const transformedValue = transformer[key](value)

    if (transformedValue == null) delete query[mappedKey]
    else query[mappedKey] = transformedValue

    await router.push({ query })
  }

  const updateMultiple = async (values: Record<SearchQueryParamater, any>) => {
    const query = { ...router.currentRoute.value.query }

    for (const key of Object.keys(values) as SearchQueryParamater[]) {
      const value = values[key]

      if (!allowableParamaters.has(key))
        throw new Error(
          `'${key}' is not an allowable query paramater. Allowable values are: ${[...allowableParamaters.keys()].join(', ')}`
        )

      if (!validator[key](value)) continue

      const mappedKey = mapper[key]
      const transformedValue = transformer[key](value)

      if (transformedValue == null) delete query[mappedKey]
      else query[mappedKey] = transformedValue
    }

    await router.push({ query })
  }

  return {
    current,
    currentHasMapBounds,
    update,
    updateMultiple
  }
}
